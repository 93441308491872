<template>
  <div class="feed">
    <div :class="`feed__header color--${color}`">
      <div class="feed__header--title">{{ title }}</div>
    </div>
    <div class="feed__content">
      <slot name="content" />
    </div>
    <div class="feed__footer" v-if="hasFooterSlot">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true
    },
    color: {
      type: String,
      default: 'empty'
    }
  },

  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer;
    }
  }
};
</script>

<style lang="scss">
.feed {
  &__header {
    padding: 1.25rem;
    position: relative;

    &:before {
      background-color: currentColor;
      bottom: 0;
      border-radius: inherit;
      content: '';
      left: 0;
      opacity: 0.1;
      position: absolute;
      pointer-events: none;
      right: 0;
      top: 0;
      transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    }
  }

  &__content {
    padding: 1.25rem;
  }

  &__header {
    &--title {
      font-weight: 400;
      font-size: 18px;
    }
  }

  &__footer {
    border-top: 1px solid;
    padding: 12px;

    &--separator-right {
      border-right: 1px solid;

      @media (max-width: 600px) {
        border-right: 0;
        border-bottom: 1px solid;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: $font-face-primary;

    &--title {
      font-size: 2.25rem;
      font-weight: 300;
    }
  }
}

.theme--light {
  .color--empty {
    color: $bg-light-elements;
    border-bottom: 1px solid $gray--20;

    div {
      color: $gray--40;
    }
  }

  .feed {
    background-color: $bg-light-elements;

    &__info {
      &--title {
        color: $gray--40;
      }

      small {
        color: $gray--20;
      }
    }

    &__footer {
      border-color: $gray--15;

      &--separator-right {
        border-color: $gray--15;
      }
    }
  }
}

.theme--dark {
  .color--empty {
    color: $bg-dark-elements;
    border-bottom: 1px solid $gray--40;

    div {
      color: $gray--20;
    }
  }

  .feed {
    background-color: $bg-dark-elements;

    &__footer {
      border-color: $gray--40;

      &--separator-right {
        border-color: $gray--40;
      }
    }
  }
}
</style>
